import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { Player, ControlBar, BigPlayButton } from 'video-react';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    background: rgba(29, 27, 30, 0.9);
    padding: 16px;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    flex-direction: column;
    justify-content: center;
    z-index: 9999;
    & > div {
        div {
            display: none;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            outline: none;
            user-select: none;
        }
    }
    @media screen and (max-width: 767px) {
        padding: 8px;
        width: calc(100% - 16px);
        height: calc(100% - 16px);
    }
`;
const CloseIcon = styled.div`
    position: fixed;
    top: 20px;
    right: 16px;
    z-index: 99999;
    cursor: pointer;
    @media screen and (max-width: 767px) {
        right: 8px;
        top: 12px;
    }
`;
const Comment = styled.div`
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
        width: 100%;
        position: fixed;
        bottom: 25px;
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
        font-family: 'Neuzeit Grotesk';
        font-size: 15px;
        line-height: 20px;
        letter-spacing: normal;
    }
`;
export default function VideoModal({
    open,
    onClose,
    video_url,
    posterImage,
    isOnPageLoad,
}) {
    const videoRef = useRef(null);

    useEffect(() => {
        if (open && !isOnPageLoad) {
            videoRef.current.play();
        }
        return () => {};
    }, [open, isOnPageLoad]);

    const handleClose = () => {
        videoRef.current.pause();
        onClose();
    };

    return (
        <Overlay style={{ display: open ? 'flex' : 'none' }}>
            <CloseIcon onClick={() => handleClose()}>
                <MdClose size={16} color="white" />
            </CloseIcon>
            <Player
                ref={videoRef}
                fluid={true}
                autoPlay={false}
                loop={true}
                src={video_url}
                poster={posterImage}
            >
                <BigPlayButton position="center" />
                <ControlBar autoHide={false} />
            </Player>
            <Comment>For better experience, turn device horizontally.</Comment>
        </Overlay>
    );
}
