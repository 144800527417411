import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Controller, Scene } from 'react-scrollmagic';

import getParameterByName from '../utils/getParameterByName';

import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import Hero4 from '../components/hero4';

import PageTabs from '../components/pageTabs';
import Article from '../components/article';
import FeaturesPanel from '../components/featuresPanel';
import TimelyAsset from '../components/timelyAsset';
import VideoModal from '../components/videoModal';
import ProductsHighlight from '../components/productsHighlight';
import CTAComponent from '../components/cta';
import { Wave3, Wave2 } from '../components/wave';
import GoldenButton from '../components/buttons/GoldenButton';
import { MdPlayArrow } from 'react-icons/md';

import Wave21Img from './../assets/images/wave21.png';
import Wave21MobileImg from './../assets/images/wave21-mobile.png';
import Wave22Img from './../assets/images/wave22.png';
import Wave22MobileImg from './../assets/images/wave22-mobile.png';
import Wave2Img from './../assets/images/wave2.png';
import Wave2MobileImg from './../assets/images/wave2-mobile.png';

import VideoPosterImage from './../assets/images/video-poster.png';

const Intro = styled.div`
    background: #fafafa;
    padding-top: 8px;
    padding-bottom: 90px;
    padding-left: 64px;
    padding-right: 64px;
    width: calc(100% - 128px);
    @media screen and (max-width: 767px) {
        padding-top: 8px;
        padding-bottom: 114px;
        padding-left: 24px;
        padding-right: 24px;
        width: calc(100% - 48px);
    }
`;
const DataStudio = styled.div`
    margin-top: 180px;
    display: block;
    @media screen and (max-width: 767px) {
        margin-top: 120px;
    }
`;
const DataStudioHeading = styled.div`
    text-align: center;
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    color: #19171a;
    letter-spacing: -1.5px;
    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
    }
`;
const DataStudioContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 52px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 24px;
    }
`;
const DataStudioItem = styled.div`
    width: calc(25% - 65px);
    padding: 16px 32px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    &:last-child {
        border: none;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 24px 0;
    }
`;
const DataStudioItemImg = styled.div`
    display: flex;
    justify-content: center;
`;
const DataStudioItemHeading = styled.div`
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    color: #19171a;
    font-family: 'Renogare-Regular';
    letter-spacing: -0.2px;
`;
const DataStudioItemDesc = styled.div`
    letter-spacing: normal;
    color: rgba(25, 23, 26, 0.7);
    font-size: 15px;
    line-height: 20px;
    font-family: 'Neuzeit Grotesk';
    text-align: center;
    margin-top: 8px;
`;
const Main = styled.div`
    margin-top: 80px;
    @media screen and (max-width: 767px) {
        margin-top: 96px;
    }
`;
const DataStudioFooter = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
`;
const Audience = styled.div`
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 171px;
    width: calc(100% - 128px);
    background: #fafafa;
    @media screen and (max-width: 767px) {
        width: calc(100% - 48px);
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 104px;
    }
`;
const AudienceHeader = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 112px;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
        padding: 0;
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 0;
    }
`;
const AudienceDesc = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    letter-spacing: normal;
    color: rgba(25, 23, 26, 0.9);
    text-align: left;
    margin-top: 0;
    width: 60%;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 22px;
        text-align: center;
    }
`;
const AudienceHeading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    text-align: left;
    color: #19171a;
    width: 40%;
    @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        text-align: center;
    }
`;
const AudienceContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 21px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`;
const AudienceItem = styled.div`
    width: calc(33.33% - 80px);
    padding: 30px 40px;
    display: block;
    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 24px 0;
    }
`;
const AudienceImg = styled.div`
    height: 240px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 767px) {
        height: 128px;
    }
`;
const AudienceTitle = styled.div`
    color: #19171a;
    font-family: 'Neuzeit Grotesk';
    font-size: 23px;
    line-height: 32px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-top: 25px;
    @media screen and (max-width: 767px) {
        margin-top: 15px;
    }
`;
const Tab1 = styled.div``;

const TabSection = styled.div`
    .tab-spacer {
        padding: 0 !important;
    }
`;

const TabPanel = styled.div`
    padding: 16px 64px;
    width: 100%;
    position: ${props => (props.stickyPosition ? 'fixed' : 'absolute')};

    background: transparent;
    z-index: 999;
    @media screen and (max-width: 767px) {
        padding: 8px 24px 8px 24px;
    }
`;
const Wave32 = styled.div`
    height: 200px;
    & > * {
        margin-top: 20px;
        width: 100%;
        position: absolute;
        @media screen and (max-width: 767px) {
            margin-top: 0;
            position: relative;
        }
    }
    @media screen and (max-width: 767px) {
        height: auto;
    }
`;

const hasWindow = typeof window !== 'undefined';

export default function DataAgencyPage({ data }) {
    const tabs = ['DATA AGENCY', 'DATA STUDIO'];
    const [currentTab, setCurrentTab] = useState(tabs[0]);
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [showVideoOnLoad, setShowVideoOnLoad] = useState(false);
    const {
        seo_content,
        hero,
        data_studio_intro,
        data_studio_article,
        data_studio_features,
        timely_asset,
        timely_asset_traditional_creative_agency,
        timely_asset_traditional_streamlined_with_audigent,
        managed_service_intro,
        managed_service_feature,
        book_now,
    } = data.butterPage;

    useEffect(() => {
        const showVid = hasWindow
            ? getParameterByName('showVideo', window.location) === 'true'
            : false;
        setVideoModalOpen(showVid);
        setShowVideoOnLoad(showVid);
        return () => {};
    }, []);

    const handleVideoModal = status => {
        setVideoModalOpen(status);
    };
    return (
        <Layout menu="FOR ADVERTISERS">
            <SEOComponent
                title="Data Agency"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Hero4 {...hero[0]}></Hero4>
            <TabSection>
                <Controller>
                    <Scene
                        duration={0}
                        pin={{ pushFollowers: true }}
                        pinSettings={{ spacerClass: 'tab-spacer' }}
                        enabled={true}
                        triggerHook={'onLeave'}
                    >
                        <TabPanel>
                            <PageTabs
                                values={tabs}
                                onClick={e => setCurrentTab(e)}
                                current={currentTab}
                                type={1}
                            />
                        </TabPanel>
                    </Scene>
                    <Intro>
                        {currentTab === tabs[1] ? (
                            <DataStudio>
                                <DataStudioHeading>
                                    {data_studio_intro[0].title}
                                </DataStudioHeading>
                                <DataStudioContent>
                                    {data_studio_intro[0].features.map(val => {
                                        return (
                                            <DataStudioItem key={val.title}>
                                                <DataStudioItemImg>
                                                    <img
                                                        src={val.image}
                                                        width="56px"
                                                        height="56px"
                                                        alt={val.title}
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                    />
                                                </DataStudioItemImg>
                                                <DataStudioItemHeading>
                                                    {val.title}
                                                </DataStudioItemHeading>
                                                <DataStudioItemDesc>
                                                    {val.description}
                                                </DataStudioItemDesc>
                                            </DataStudioItem>
                                        );
                                    })}
                                </DataStudioContent>
                                <DataStudioFooter>
                                    <GoldenButton
                                        type="none"
                                        onClick={() => handleVideoModal(true)}
                                    >
                                        <MdPlayArrow
                                            size={24}
                                            color={'white'}
                                        />{' '}
                                        <div>SEE IT IN ACTION</div>
                                    </GoldenButton>
                                </DataStudioFooter>
                            </DataStudio>
                        ) : (
                            <Audience>
                                <AudienceHeader>
                                    <AudienceHeading>
                                        {managed_service_intro[0].title}
                                    </AudienceHeading>
                                    <AudienceDesc>
                                        {managed_service_intro[0].description}
                                    </AudienceDesc>
                                </AudienceHeader>
                                <AudienceContent>
                                    {managed_service_intro[0].features.map(
                                        val => {
                                            return (
                                                <AudienceItem key={val.title}>
                                                    <AudienceImg>
                                                        <img
                                                            src={val.image}
                                                            height="100%"
                                                            width="100%"
                                                            alt={val.title}
                                                            style={{
                                                                objectFit:
                                                                    'contain',
                                                            }}
                                                        />
                                                    </AudienceImg>
                                                    <AudienceTitle>
                                                        {val.title}
                                                    </AudienceTitle>
                                                </AudienceItem>
                                            );
                                        }
                                    )}
                                </AudienceContent>
                            </Audience>
                        )}
                    </Intro>
                    <Main>
                        {currentTab === tabs[1] ? (
                            <Tab1>
                                <Article
                                    {...data_studio_article}
                                    direction={'left'}
                                    spacing={1}
                                ></Article>
                                <Wave3 />
                                <FeaturesPanel
                                    {...data_studio_features[0]}
                                    desktopImg={Wave22Img}
                                    mobileImg={Wave22MobileImg}
                                    type={1}
                                    spacing={1}
                                />
                                <FeaturesPanel
                                    {...data_studio_features[1]}
                                    desktopImg={Wave21Img}
                                    mobileImg={Wave21MobileImg}
                                    direction={'reverse'}
                                    type={1}
                                />
                                <TimelyAsset
                                    {...timely_asset[0]}
                                    data={[
                                        {
                                            top: '2-3 WEEK TURNAROUND',
                                            heading:
                                                'Traditional Creative Agency',
                                            data: timely_asset_traditional_creative_agency,
                                        },
                                        {
                                            top: '2-3 DAY TURNAROUND',
                                            heading:
                                                'Streamlined with Audigent',
                                            data: timely_asset_traditional_streamlined_with_audigent,
                                        },
                                    ]}
                                />
                            </Tab1>
                        ) : (
                            <React.Fragment>
                                <FeaturesPanel
                                    {...managed_service_feature[0]}
                                    type={1}
                                />
                                {/**
                  <Monetize {...data_partners[0]}></Monetize>
                 */}
                                <Wave32>
                                    <Wave2></Wave2>
                                </Wave32>
                                <FeaturesPanel
                                    {...managed_service_feature[1]}
                                    desktopImg={Wave2Img}
                                    mobileImg={Wave2MobileImg}
                                    type={1}
                                    direction="reverse"
                                />
                                <ProductsHighlight
                                    {...managed_service_feature[2]}
                                />
                            </React.Fragment>
                        )}
                    </Main>
                </Controller>
            </TabSection>

            <CTAComponent {...book_now[0]} />
            <VideoModal
                {...data_studio_intro[0]}
                open={videoModalOpen}
                onClose={() => {
                    handleVideoModal(false);
                }}
                posterImage={VideoPosterImage}
                isOnPageLoad={showVideoOnLoad}
            />
        </Layout>
    );
}

export const dataAgencyPageQuery = graphql`
    query {
        butterPage(slug: { eq: "data-agency" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
                description
                desktop_image
                mobile_image
                mask_image
            }
            data_studio_intro {
                title
                features {
                    title
                    image
                    description
                }
                video_url
            }
            data_studio_article {
                title
                description
                image
            }
            data_studio_features {
                title
                description
                features {
                    title
                    image
                    description
                }
            }

            timely_asset {
                title
                description
            }
            timely_asset_traditional_creative_agency {
                title
            }
            timely_asset_traditional_streamlined_with_audigent {
                title
            }
            managed_service_intro {
                title
                description
                features {
                    title
                    description
                    image
                }
            }
            managed_service_feature {
                title
                description
                features {
                    title
                    description
                    image
                }
            }
            data_partners {
                title
                first_row {
                    title
                    image
                }
                second_row {
                    title
                    image
                }
            }
            book_now {
                button_title
                title
            }
        }
    }
`;
